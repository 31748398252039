import { useState } from 'react';
import { Main } from './Main/Main';
import { DriverJobDescription } from './Info/DriverJobDescription';

export const Home = (props) => {
  const [hovered, setHovered] = useState({jobId:null, compId:null});

  return (
    <div style={{display:"flex", flexDirection:"row", height:"100%"}}>
        <div style={{flex:2, padding:16, display:"flex"}}>
            <Main hovered={hovered} onJobHover={setHovered} />
        </div>
        <div style={{flex:1, padding:16, display:"flex"}}>
            <DriverJobDescription hovered={hovered} />
        </div>
    </div>
  );
}
