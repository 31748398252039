import { Provider } from "mobx-react";
import { ThemeProvider } from "styled-components";
import { Colors, Fonts } from "assets/theme";
import { stores } from "./stores";
import { AppRoutes } from "./AppRoutes";
import './App.css';
//import './variables.less'

export default function App() {
  return (
    <Provider {...stores}>
      <ThemeProvider theme={{ Colors, fonts: Fonts }}>
        <AppRoutes />
      </ThemeProvider>
    </Provider>
  );
}
