import { message } from "antd";
// TODO refactor this, maybe one function to all methods, maybe break it down, maybe axios

// SIMPLE CREATE POST REQUEST
let API = "http://localhost:8001";

if (process.env.REACT_APP_ENV === "production") {
  API = "https://leads-api.metrobi.com";
}

function createRequest(endpoint, data, method) {
  return fetch(API + endpoint, {
    method: method,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((r) =>
      r.json().then((json) => {
        if (r.statusText !== "OK" && r.status !== 200) {
          return Promise.reject(json);
        }
        if (json?.message) {
          message.success(json?.message, 1);
        }
        return json.data;
      })
    )
    .catch((e) => {
      if (e?.message) {
        message.error(e?.message, 2);
      }
      return Promise.reject(e?.message);
    });
}

export function createGetRequest(endpoint) {
  const headers = new Headers();
  return fetch(API + endpoint, {
    method: "GET",
    headers,
  })
    .then((r) =>
      r.json().then((json) => {
        if (r.statusText !== "OK") {
          return Promise.reject(json);
        }
        if (json?.message) {
          message.success(json?.message, 1);
        }
        return json.data;
      })
    )
    .catch((e) => {
      if (e?.message) {
        message.error(e?.message, 2);
      }
      return Promise.reject();
    });
}

export default createRequest;
