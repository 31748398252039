import { inject, observer } from "mobx-react";
import { List } from "antd";
import { CompanyTitle } from "./CompanyTitle";
import { CompanyJobs } from "./CompanyJobs";
import { HubspotContent } from "./HubspotContent";

export const CompanyItem = inject(
  "companiesStore",
  "uiStore"
)(
  observer((props) => {
    let latestHovered = {};
    let lastHoveredCompany = null;
    const company = props.companiesStore.companies.get(props.compId);

    const onMouseEnter = (jobId) => {
      const jobHover = () => {
        lastHoveredCompany = company.companyId; //Important that this is here

        onMouseLeave(jobId);
        latestHovered[jobId] = setTimeout(() => {
          if (props.onJobHover) {
            props.onJobHover({
              jobId: parseInt(jobId),
              compId: company.companyId,
            });
          }
        }, 300);
      };
      if (
        props.uiStore.show === "driverjobs" &&
        Number.isInteger(parseInt(jobId))
      ) {
        jobHover();
      } else if (props.uiStore.show !== "driverjobs") {
        jobHover();
      } else {
        return;
      }
    };

    const onMouseLeave = (jobId) => {
      const timer = latestHovered[jobId];
      if (timer) {
        clearTimeout(timer);
        latestHovered[jobId] = null;
        lastHoveredCompany = null;
      }
    };

    const jobs = props.showHistoric
      ? [...company.jobs.values()].filter((j) => j.prequalify !== null)
      : [...company.jobs.values()]
          .filter((j) => j.state === props.uiStore.zone)
          .filter((j) => j.prequalify === null)
          .sort((a, b) => (a.postDate > b.postDate ? -1 : 1));

    return props.uiStore.show === "driverjobs" && jobs.length < 1 ? null : (
      <List.Item style={{ paddingLeft: 6, paddingRight: 6 }}>
        <List.Item.Meta
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          onMouseOver={() => {
            if (lastHoveredCompany !== company.companyId) {
              onMouseEnter();
            }
          }}
          title={
            <CompanyTitle
              compId={company.companyId}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
              addToBlacklist={props.addToBlacklist}
            />
          }
          description={
            props.uiStore.show === "hubspot" ? (
              <HubspotContent compId={company.companyId} />
            ) : (
              <CompanyJobs
                compId={company.companyId}
                jobs={jobs}
                onJobHover={props.onJobHover}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
              />
            )
          }
        />
      </List.Item>
    );
  })
);
