import createRequest, { createGetRequest } from "client";
import {
  makeObservable,
  observable,
  action,
  computed,
  runInAction,
} from "mobx";
import { CompanyModel } from "./companyModel";
import { stores } from "./index.js";
// import mock from "mock";

export default class companiesStore {
  _companies = new observable.map();
  loadingData = false;
  lastScrapedDate = null;
  jobsPerState = null;

  constructor() {
    makeObservable(this, {
      _companies: observable,
      loadingData: observable,
      lastScrapedDate: observable,
      jobsPerState: observable,

      companies: computed,
      marked: computed,
      getInstagramLeads: action,
      getDriverJobs: action,
      getHubspotCompanies: action,
      add_company: action,
      getJobsPerState: action,
      replaceInstagramCompany: action,
    });

    // TODO this route is broken
    this.getJobsPerState();
  }

  //computed
  get companies() {
    const comps = new observable.map();

    if (stores.uiStore.show === "driverjobs") {
      this._companies.forEach((c) => {
        // let exists = false;
        // c.jobs.forEach((j) => {
        // if (j.state === stores.uiStore.zone) {
        // exists = true;
        // }
        // });
        if (c.jobs.size) {
          comps.set(c.companyId, c);
        }
      });
    } else if (stores.uiStore.show === "instagram") {
      this._companies.forEach((c) => {
        if (c.instagramProfile && (!c.jobs || c.jobs.size < 1)) {
          comps.set(c.companyId, c);
        }
      });
    } else if (stores.uiStore.show === "hubspot") {
      this._companies.forEach((c) => {
        if (c.hubspotData) {
          comps.set(c.companyId, c);
        }
      });
    }

    return comps;
  }

  get marked() {
    const comps = new observable.map();
    if (stores.uiStore.show === "driverjobs") {
      this._companies.forEach((c) => {
        if (c.jobs.size && c.marked) comps.set(c.companyId, c);

        // let exists = false;
        // c.jobs.forEach((j) => {
        // if (j.state === stores.uiStore.zone) {
        // exists = true;
        // }
        // });
      });
    } else if (stores.uiStore.show === "instagram") {
      this._companies.forEach((c) => {
        if (c.instagramProfile && (!c.jobs || c.jobs.size < 1) && c.marked) {
          comps.set(c.companyId, c);
        }
      });
    }
    return comps;
  }

  // action

  getJobsPerState() {
    return createGetRequest("/total_jobs_per_state")
      .then((response) => {
        this.jobsPerState = response;
      })
      .catch((e) => console.log(e));
  }

  async getDriverJobs() {
    runInAction(() => {
      this.loadingData = true;
    });
    const raw_data = await createRequest(
      "/get_jobs",
      {
        user: stores.authStore.user,
        state_code: stores.uiStore.zone,
      },
      "POST"
    );
    if (Boolean(raw_data)) {
      const data = raw_data.companies;
      data.forEach((c) => {
        this.add_company(c);
      });
    }
    runInAction(() => {
      this.loadingData = false;
      this.lastScrapedDate = raw_data?.last_scraped;
    });

    // mock.companies.forEach((comp) => {
    // this.add_company(comp);
    // });
  }

  async getInstagramLeads() {
    runInAction(() => {
      this.loadingData = true;
    });
    const raw_data = await createRequest(
      "/get_instagram_profiles",
      { user: stores.authStore.user },
      "POST"
    );
    if (Boolean(raw_data)) {
      const companies = raw_data;
      companies.forEach((c) => {
        this.add_company(c);
      });
    }
    //
    runInAction(() => {
      this.loadingData = false;
    });
  }

  async getHubspotCompanies() {
    if (stores.authStore.limited) return;
    runInAction(() => {
      this.loadingData = true;
    });
    const raw_data = await createRequest(
      "/get_hubspot_companies",
      { user: stores.authStore.user },
      "POST"
    );
    if (Boolean(raw_data)) {
      const companies = raw_data;
      companies.forEach((c) => {
        this.add_company(c);
      });
    }
    //
    runInAction(() => {
      this.loadingData = false;
    });

    // mock.companies.forEach((comp) => {
    // this.add_company(comp);
    // });
  }

  async selfAssign() {
    runInAction(() => {
      this.loadingData = true;
    });
    const raw_data = await createRequest(
      "/self_assign",
      { user: stores.authStore.user },
      "POST"
    );
    stores.authStore.fetchNumber();
    if (Boolean(raw_data)) {
      const companies = raw_data;
      companies.forEach((c) => {
        this.add_company(c);
      });
    }
    //
    runInAction(() => {
      this.loadingData = false;
    });
  }

  async getIndividualCompany(company_id) {
    runInAction(() => {
      this.loadingData = true;
    });
    const raw_data = await createRequest(
      "/get_company",
      { company_id },
      "POST"
    );
    if (Boolean(raw_data)) {
      this.add_company(raw_data);
    }
    //
    runInAction(() => {
      this.loadingData = false;
    });

    // mock.companies.forEach((comp) => {
    // this.add_company(comp);
    // });
  }

  replaceInstagramCompany(company, old_id) {
    this._companies.delete(old_id);
    this._companies.set(company.companyId, company);
  }

  add_company(comp, isfullmodel = false) {
    let comp_obj;
    if (isfullmodel) {
      comp_obj = comp;
    } else {
      comp_obj = new CompanyModel(comp);
    }

    let exists_key = null;

    if (
      (comp.instagram_profile && comp.instagram_profile.ig_id) ||
      (comp.instagramProfile && comp.instagramProfile.ig_id)
    ) {
      // json or obj
      const ig_id =
        comp.instagram_profile?.ig_id || comp.instagramProfile?.ig_id;
      this._companies.forEach((c, k) => {
        if (c.instagramProfile && c.instagramProfile.ig_id === ig_id) {
          exists_key = k;
        }
      });
    } else if (comp.hsId || comp.hubspotId) {
      // json or obj
      const hsId = comp.hsId || comp.hubspotId;

      this._companies.forEach((c, k) => {
        if (c.hubspotId === hsId) {
          exists_key = k;
        }
      });
    }
    //
    this._companies.set(comp_obj.companyId, comp_obj);
    if (exists_key) {
      if (stores.uiStore.dataCollectCompany === exists_key) {
        stores.uiStore.setDataCollectCompany(comp_obj.companyId);
      }
      this._companies.delete(exists_key);
    }
  }
}
