const segments = {
  Bakery: "bakery",
  Beverages: "beverages",
  Charcuterie: "board",
  Coffee: "coffee",
  Distillery: "brewery",
  Farm: "farm",
  Flower: "florist",
  "General Distributor": "general_distributor",
  "Meal Delivery": "meal",
  "Commissary Kitchen": "comissary_kitchen",
  Food: "food",
  Other: "other",
  Laundry: 'laundry'
};

export default segments;
