import { makeObservable, observable } from "mobx";

export class HubspotModel {
  hsId;
  hsName;
  hsDomain;
  hsZones;
  hsInstagramHandle;
  leadStatus;
  needInfo;
  hsFacebook
  hsSegment
  hsLinkedin

  constructor(data) {
    this.hsId = data.hsId
    this.hsName = data.hsName
    this.hsDomain = data.hsDomain
    this.hsZones = data.hsZones || []
    this.hsInstagramHandle = data.hsInstagramHandle
    this.leadStatus = data.hsLeadStatus
    this.needInfo = data.hsNeedInfo
    this.hsFacebook = data.hsFacebook
    this.hsSegment = data.hsSegment
    this.hsLinkedin = data.hsLinkedin

    makeObservable(this, {
      // observable
      hsId: observable,
      hsName: observable,
      hsDomain: observable,
      hsZones: observable,
      hsInstagramHandle: observable,
      leadStatus: observable,
      needInfo: observable,
      hsFacebook: observable,
      hsSegment: observable,
      hsLinkedin: observable
    });
  }
}
