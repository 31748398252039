import { observer, inject } from "mobx-react";
import { CompanyName, Link } from "./CompanyTitle.styled";
import createRequest from "client";
import { Col, Row, Button } from "antd";

export const CompanyTitle = inject(
  "companiesStore",
  "authStore"
)(
  observer((props) => {
    const company = props.companiesStore.companies.get(props.compId);
    const user = props.authStore.user;

    const addCompanyToBlacklist = (id) => {
      props.addToBlacklist();
      return createRequest(
        "/blacklist_companies",
        {
          company: id,
          user: `${user}`,
        },
        "POST"
      )
        .then(() => {
          // alert(`The company ${company.companyName} was blacklisted`);
        })
        .catch((e) => {
          alert(`Error trying to add ${company.companyName} to blacklist`);
        });
    };

    return (
      <Row
        justify="space-between"
        onMouseEnter={() => props.onMouseEnter()}
        onMouseLeave={() => props.onMouseLeave()}
      >
        <Col flex={"1"}>
          <Row>
            <Col>
              <CompanyName
                target="_blank"
                rel="noreferrer"
                href={
                  company.website
                    ? `http://${company.website}`
                    : "http://www.google.com/search?q=" +
                      company.companyName.replace(" ", "+")
                }
              >
                {company.companyName}
              </CompanyName>
            </Col>
          </Row>
          <Row>
            <Col flex={"none"}>
              <Link
                target="_blank"
                rel="noreferrer"
                href={
                  "http://www.google.com/search?q=" +
                  company.companyName.replace(" ", "+")
                }
              >
                Google
              </Link>
            </Col>
            {company.hubspotId && (
              <Col flex={"none"}>
                <span>|</span>
                <Link
                  target="_blank"
                  rel="noreferrer"
                  href={
                    "https://app.hubspot.com/contacts/4444371/company/" +
                    company.hubspotId
                  }
                >
                  Hubspot
                </Link>
              </Col>
            )}
          </Row>
        </Col>
        <Col flex={"none"}>
          <Button
            danger
            onClick={(e) => {
              e.preventDefault();
              addCompanyToBlacklist(company.companyId);
            }}
          >
            Add to Blacklist
          </Button>
        </Col>
      </Row>
    );
  })
);
