import { useRef, useEffect, useState } from "react";

import { EditOutlined } from "@ant-design/icons";
import { Form, Button, Modal } from "antd";
import useBoolean from "hooks/useBoolean";
import { inject, observer } from "mobx-react";
import FormInput from "./FormInput";

export const ContactModal = inject("companiesStore")(
  observer((props) => {
    const [formData, setFormData] = useState({});

    const company = props.companiesStore._companies.get(props?.companyId);
    const contactId = props?.contact?.contact_id;

    const formRef = useRef();

    useEffect(() => {
      if (props.defaultEmail) {
        formRef.current.setFieldsValue({
          email: props.defaultEmail,
        });
      }
    }, []);

    const isVisible = useBoolean(props.defaultVisible);
    const [form] = Form.useForm();

    const closeModal = () => {
      isVisible.setFalse();
      props.onClose && props.onClose();
    };

    const saveContact = (formData) => {
      if (contactId) {
        // request api for update if there is a contact id
        company.updateCompanyContact({ contact_id: contactId, ...formData });
        isVisible.setFalse();
      } else if (company._companyId || company.hubspotData) {
        // request creation if there is a company id  and no contactId
        company.addCompanyContact(formData);
        form.resetFields();
        isVisible.setFalse();
      } else {
        form.resetFields();
        isVisible.setFalse();
      }
      closeModal();
    };

    const updateFormData = (k, value) => {
      let _new = {};
      if (k === "phones") {
        _new["phones"] = formRef.current?.getFieldValue("phones");
      } else {
        _new[k] = value;
      }

      setFormData({ ...formData, ..._new });
      formRef.current.setFieldsValue(_new);
    };

    const getData = () => {
      if (!props.contact) {
        return {};
      }

      const contact = props.contact;

      const initialValues = {};

      initialValues.name = contact.name;
      initialValues.lastname = contact.lastname;
      initialValues.title = contact.title;
      initialValues.email = contact.email;
      initialValues.phone = contact.phone;
      initialValues.linkedin = contact.linkedin;

      if (Object.keys(formData).length < 1) {
        setFormData(initialValues);
      }

      return initialValues;
    };

    // do this later, maybe use state for that
    // const customRule = {
    // validator: async (_, ok) => {
    // let validate = true;
    // const contactChannels = form.getFieldsValue();
    // const channels = ["email", "phone", "linkedin"];
    // channels.forEach((c) => {
    // if (contactChannels[c] !== undefined && contactChannels[c] !== "") {
    // validate = false;
    // }
    // });
    // if (validate) {
    //   return Promise.reject(
    //     new Error("Please fill at least one these fields")
    //   );
    // }
    // },
    // };

    return (
      <>
        {props.edit ? (
          <EditOutlined
            onClick={isVisible.setTrue}
            style={{ cursor: "pointer", fontSize: 20, marginBottom: 8 }}
          />
        ) : (
          <Button onClick={isVisible.setTrue} style={{ margin: 24 }}>
            + Add contact
          </Button>
        )}
        <Modal
          title={contactId === null ? "New contact" : "Edit contact"}
          visible={isVisible.value}
          onCancel={closeModal}
          onOk={form.submit}
          okText="Save"
        >
          <Form
            onFinish={saveContact}
            initialValues={getData()}
            form={form}
            name="contact"
            ref={formRef}
            onValuesChange={(changes) => {
              Object.keys(changes).map((k) => {
                updateFormData(k, changes[k]);
              });
            }}
          >
            <FormInput
              placeholder="Name"
              name="name"
              addonName={props?.contact?.name ? null : "google"}
              urlArgs={{
                name: company.companyName,
                companyOwner: props?.contact?.name ? false : true,
              }}
            />
            <FormInput placeholder="Last Name" name="lastname" />
            <FormInput placeholder="Title" name="title" />
            <FormInput placeholder="E-mail" name="email" emailMask />
            <FormInput placeholder="Phone" name="phone" />
            <FormInput
              placeholder="Linkedin"
              name="linkedin"
              addonName={formData.name || formData.linkedin ? "linkedin" : null}
              urlArgs={{
                name: company.companyName,
                contactName: formData.name + " " + (formData.lastname || ""),
                linkedinUrl: formData.linkedin ? formData.linkedin : null,
              }}
            />
          </Form>
        </Modal>
      </>
    );
  })
);
