import styled from "styled-components";

export const NeedInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`

export const NeedInfoText = styled.p`
  margin: 0;
`

export const CompanyBody = styled.div`
  display: flex;
  margin-top: 8px;
`
