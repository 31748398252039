import { makeObservable, observable, action } from "mobx";

export default class uiStore {
  zone = "MA";
  show = "driverjobs";
  dataCollectCompany = null;

  constructor() {
    makeObservable(this, {
      zone: observable,
      show: observable,
      dataCollectCompany: observable,

      setZone: action,
      setShow: action,
      setDataCollectCompany: action,
      setHubspotAsDefault: action,
    });
  }

  setZone = (z) => {
    this.zone = z;
  };

  setShow(s) {
    this.show = s;
  }

  setDataCollectCompany(c) {
    this.dataCollectCompany = c;
  }

  setHubspotAsDefault() {
      this.show = 'hubspot'
  }
}
