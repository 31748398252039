const deliveryZones = {
  AK: {
    name: "Alaska",
  },
  AL: {
    name: "Alabama",
  },
  AR: {
    name: "Arkansas",
  },
  AS: {
    name: "American Samoa",
  },
  AZ: {
    name: "Arizona",
  },
  CA: {
    name: "California",
    activeState: true,
  },
  CO: {
    name: "Colorado",
    activeState: true,
  },
  CT: {
    name: "Connecticut",
    activeState: true,
  },
  DC: {
    name: "District of Columbia",
    activeState: true,
  },
  DE: {
    name: "Delaware",
  },
  FL: {
    name: "Florida",
    activeState: true,
  },
  GA: {
    name: "Georgia",
    activeState: true,
  },
  GU: {
    name: "Guam",
  },
  HI: {
    name: "Hawaii",
  },
  IA: {
    name: "Iowa",
  },
  ID: {
    name: "Idaho",
  },
  IL: {
    name: "Illinois",
    activeState: true,
  },
  IN: {
    name: "Indiana",
  },
  KS: {
    name: "Kansas",
  },
  KY: {
    name: "Kentucky",
  },
  LA: {
    name: "Louisiana",
  },
  MA: {
    name: "Massachusetts",
    activeState: true,
  },
  MD: {
    name: "Maryland",
    activeState: true,
  },
  ME: {
    name: "Maine",
    activeState: true,
  },
  MI: {
    name: "Michigan",
  },
  MN: {
    name: "Minnesota",
  },
  MO: {
    name: "Missouri",
  },
  MP: {
    name: "Northern Mariana Islands",
  },
  MS: {
    name: "Mississippi",
  },
  MT: {
    name: "Montana",
  },
  NC: {
    name: "North Carolina",
  },
  ND: {
    name: "North Dakota",
  },
  NE: {
    name: "Nebraska",
  },
  NH: {
    name: "New Hampshire",
    activeState: true,
  },
  NJ: {
    name: "New Jersey",
    activeState: true,
  },
  NM: {
    name: "New Mexico",
  },
  NV: {
    name: "Nevada",
  },
  NY: {
    name: "New York",
    activeState: true,
  },
  OH: {
    name: "Ohio",
  },
  OK: {
    name: "Oklahoma",
  },
  OR: {
    name: "Oregon",
  },
  PA: {
    name: "Pennsylvania",
    activeState: true,
  },
  PR: {
    name: "Puerto Rico",
  },
  RI: {
    name: "Rhode Island",
    activeState: true,
  },
  SC: {
    name: "South Carolina",
  },
  SD: {
    name: "South Dakota",
  },
  TN: {
    name: "Tennessee",
  },
  TX: {
    name: "Texas",
  },
  UT: {
    name: "Utah",
  },
  VA: {
    name: "Virginia",
    activeState: true,
  },
  VI: {
    name: "Virgin Islands",
  },
  VT: {
    name: "Vermont",
  },
  WA: {
    name: "Washington",
    activeState: true,
  },
  WI: {
    name: "Wisconsin",
  },
  WV: {
    name: "West Virginia",
  },
  WY: {
    name: "Wyoming",
  },
};
export default deliveryZones;
