import { makeObservable, observable, action } from "mobx";

export class DriverJobModel {
  company;
  jobId;

  source;
  jobLink;
  jobTitle;
  descriptionHtml;
  state;
  prequalify = null;
  job_location;

  constructor(company, job) {
    this.company = company;
    this.jobId = job.job_id;

    this.source = job.source;
    this.jobLink = job.job_link;
    this.jobTitle = job.job_title;
    this.jobDate = job.post_date;

    this.descriptionHtml = job.description_html;
    this.state = job.state;
    this.jobLocation = job.job_location;

    makeObservable(this, {
      jobId: observable,
      jobLink: observable,
      jobTitle: observable,
      jobDate: observable,
      source: observable,
      descriptionHtml: observable,
      state: observable,
      prequalify: observable,
      jobLocation: observable,

      setPrequalify: action,
    });
  }

  setPrequalify(condition, commit = false) {
    this.prequalify = condition;
    if (commit) this.company.mark();
  }
}
