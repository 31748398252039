import { LoadingOutlined } from "@ant-design/icons";
import { inject, observer } from "mobx-react";
import { Route, Redirect } from "react-router-dom";

const RestrictedRouteContent = inject("authStore")(
  observer((props) => {
    const authenticated = props.authStore.authenticated;

    return authenticated === false ? (
      <Redirect to={{ pathname: "/signin" }} />
    ) : authenticated === null ? (
      <div style={{ marginTop: "10%", textAlign: "center" }}>
        <LoadingOutlined style={{ fontSize: 48, color: "white" }} spin />
      </div>
    ) : (
      <props.component {...props.routerProps} />
    );
  })
);

const RestrictedRoute = (props) => (
  <Route
    exact
    path={props.path}
    render={(routerProps) => (
      <RestrictedRouteContent
        routerProps={routerProps}
        component={props.component}
      />
    )}
  />
);

export default RestrictedRoute;
