import { inject, observer } from "mobx-react";

export const Number = inject("authStore")(
  observer((props) => {
    const number = props.authStore.number;
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          height: "100%",
          marginLeft: 8,
        }}
      >
        Number of leads: {number || 0}
      </div>
    );
  })
);
