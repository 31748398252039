import { PlusOutlined } from "@ant-design/icons";

export const Suggestor = (props) => {


    return (
        <div style={{padding:4}} onClick={()=>{props.onClick && props.onClick()}}>
            {props.text} <PlusOutlined/>
        </div>
    );    
}