import createRequest from "client";
import { Button, Select } from "antd";
import { observer, inject } from "mobx-react";
import { parse, format } from "date-fns";
import { Colors } from "assets/theme";
import deliveryZones from "constants/deliveryZones";
import React, { useState } from "react";
import { stores } from "stores";

import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";

export const CompanyJobs = inject(
  "companiesStore",
  "uiStore"
)(
  observer((props) => {
    const [state, setState] = useState(null);

    const company = props.companiesStore.companies.get(props.compId);

    const stateSelection =
      state || (company.states.length && company.states[0]) || null;

    const setLabel = (label, jobId) => {
      // const today = new Date();
      // const lastModified = parse(
      //   company.leadEnrichDate,
      //   "yyyy-MM-dd",
      //   new Date()
      // );
      if (label === "fit") {
        const data = {
          user: stores.authStore.user,
          company_id: company.companyId,
          job_id: jobId,
          fit: true,
        };
        createRequest("/driverjob/update_prequalify", data, "POST")
          .then(() => {
            company.jobs.get(jobId).setPrequalify("fit");
          })
          .catch(() => {
            props.uiStore.setDataCollectCompany(null);
          });

        // if (
        //   !company.leadEnrichDate ||
        //   differenceInDays(today, lastModified) > 4
        // ) {
        //   props.onJobHover({ jobId, comp: company.companyId });
        //   props.uiStore.setDataCollectCompany(company.companyId);
        // }
      }

      if (label === "notfit") {
        const data = {
          user: stores.authStore.user,
          company_id: company.companyId,
          job_id: jobId,
          fit: false,
        };
        company.jobs.get(jobId).setPrequalify("notfit");
        createRequest("/driverjob/update_prequalify", data, "POST")
          .then(() => {
            company.jobs.get(jobId).setPrequalify("notfit", true);
          })
          .catch(() => {
            company.jobs.get(jobId).setPrequalify(null);
          });
      }
    };

    const setCompanyLabel = (label) => {
      company.instagramProfile.setPrequalify(label);
      company.updateInstagramPrequalify(label === "fit", stateSelection);
    };

    const handleStateChange = (val) => {
      setState(val);
    };
    return (
      <>
        {props.jobs.length > 0 ? (
          props.jobs.map((j) => (
            <div
              key={j.jobId}
              style={{
                padding: "4px 4px 8px 4px",
                border: "0.1px solid grey",
                borderRadius: 5,
                marginTop: 15,
              }}
            >
              <div style={{ display: "flex", flexDirection: "row" }}>
                <a
                  key={j.jobId}
                  style={
                    j.jobId === (props.hovered && props.hovered.jobId)
                      ? {
                          ...jobLinkStyle,
                          color: "red",
                        }
                      : jobLinkStyle
                  }
                  target="_blank"
                  rel="noreferrer"
                  href={j.jobLink}
                  onMouseEnter={() => props.onMouseEnter(j.jobId)}
                  onMouseLeave={() => props.onMouseLeave(j.jobId)}
                >
                  {j.jobTitle}
                </a>
                <div
                  style={{
                    fontWeight: "bold",
                    fontSize: 12,
                    margin: "auto 0px",
                    color: Colors.textSecondary,
                  }}
                >
                  -{" "}
                  {format(
                    parse(j.jobDate, "yyyy-MM-dd", new Date()),
                    "MM/dd/yyyy"
                  )}
                </div>

                <div
                  style={{
                    fontWeight: "bold",
                    fontSize: 12,
                    margin: "auto 5px",
                    color: Colors.neutral5,
                  }}
                >
                  - {j.source}
                </div>
                <div>{j.jobLocation}</div>
              </div>
              <div
                style={{ marginLeft: 8 }}
                onMouseEnter={() => props.onMouseEnter(j.jobId)}
                onMouseLeave={() => props.onMouseLeave(j.jobId)}
              >
                <Button
                  onClick={() => setLabel("fit", j.jobId)}
                  disabled={company.states.length === 0}
                  type="primary"
                  shape="circle"
                  icon={<CheckCircleOutlined />}
                  size="large"
                />
                <Button
                  onClick={() => setLabel("notfit", j.jobId)}
                  style={{ marginLeft: 16 }}
                  danger
                  type="primary"
                  shape="circle"
                  icon={<CloseCircleOutlined />}
                  size="large"
                />
              </div>

              {/* <Radio.Group
                name="radiogroup"
                onChange={(e) => setLabel(e, j.jobId)}
                value={j.prequalify}
                onMouseEnter={() => props.onMouseEnter(j.jobId)}
                onMouseLeave={() => props.onMouseLeave(j.jobId)}
              >
                <Radio key={"fit"} value={"fit"}>
                  Fit
                </Radio>
                <Radio key={"notfit"} value={"notfit"}>
                  Not fit
                </Radio>
              </Radio.Group> */}
            </div>
          ))
        ) : (
          <div>
            <Select
              onChange={handleStateChange}
              style={{ minWidth: 150, marginRight: 16 }}
              value={stateSelection}
              placeholder="States"
              showSearch
              autoComplete="new-password"
              filterOption={(input, option) => {
                if (
                  option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                ) {
                  return true;
                }

                const indexes = [];
                Object.values(deliveryZones).forEach((dz, index) => {
                  if (dz.name.toLowerCase().includes(input.toLowerCase())) {
                    indexes.push(index);
                  }
                });
                return indexes.includes(parseInt(option.key, 10));
              }}
              onSearch={() => {}}
              rules={[{ required: true, message: "State is required" }]}
            >
              {Object.keys(deliveryZones).map((key, index) => (
                <Select.Option key={index} value={key}>
                  {key}
                </Select.Option>
              ))}
            </Select>
            <Button
              onClick={() => setCompanyLabel("fit")}
              disabled={!stateSelection}
              type="primary"
              shape="circle"
              icon={<CheckCircleOutlined />}
              size="large"
            />
            <Button
              onClick={() => setCompanyLabel("notfit")}
              style={{ marginLeft: 16 }}
              danger
              type="primary"
              shape="circle"
              icon={<CloseCircleOutlined />}
              size="large"
            />
          </div>
          // <Radio.Group
          //   name="radiogroup"
          //   defaultValue={company?.instagramProfile?.prequalify}
          //   onChange={(e) => setCompanyLabel(e)}
          // >
          //   <Radio key={"fit"} value={"fit"}>
          //     Fit
          //   </Radio>
          //   <Radio key={"notfit"} value={"notfit"}>
          //     Not fit
          //   </Radio>
          // </Radio.Group>
        )}
      </>
    );
  })
);

const jobLinkStyle = {
  paddingLeft: 6,
  paddingRight: 6,
  display: "block",
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
};
