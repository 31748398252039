import {
  computed,
  makeObservable,
  observable,
  action,
  runInAction,
} from "mobx";
import { DriverJobModel } from "./driverJobModel";
import { ContactModel } from "./contactModel";
import { InstagramModel } from "./instagramModel";
import { HubspotModel } from "./hubspotModel";
import createRequest from "client";
import { stores } from "./index.js";
import segments from "constants/segments";

export class CompanyModel {
  _companyId;
  _companyName;
  _instagram;
  _website;

  _segment;
  _states;
  _linkedin;
  _facebook;
  phones;
  leadEnrichDate;
  _hubspotId;
  marked = false;
  lastModified = null;
  status;

  instagramProfile = null;

  hubspotData = null;

  jobs = new observable.map();
  contacts = new observable.map();

  constructor(company) {
    this._companyId = company.company_id;
    this._companyName = company.company_name;

    this._segment = company.segment;
    this._states = company.states || [];
    this._website = company.domain;
    this._instagram = company.instagram;
    this._linkedin = company.linkedin;
    this._facebook = company.facebook;
    this.leadEnrichDate = company.leadEnrichDate;
    this._hubspotId = company.hubspot_company_id;
    this.phones = company.phones || [];
    this.status = company.status;

    if (company.jobs) {
      company.jobs.forEach((j) => {
        this.jobs.set(j.job_id, new DriverJobModel(this, j));
      });
    }

    if (company.contacts) {
      company.contacts.forEach((c) => {
        this.contacts.set(c.contact_id, new ContactModel(c));
      });
    }
    if (company.instagram_profile) {
      this.instagramProfile = new InstagramModel(
        this,
        company.instagram_profile
      );
    }

    if (company.hubspotData) {
      this.hubspotData = new HubspotModel(company.hubspotData);
    }

    makeObservable(this, {
      _companyId: observable,
      _companyName: observable,
      _website: observable,
      _instagram: observable,
      _segment: observable,
      _states: observable,
      _linkedin: observable,
      _facebook: observable,
      phones: observable,
      leadEnrichDate: observable,
      marked: observable,
      lastModified: observable,
      status: observable,

      jobs: observable,
      contacts: observable,
      _hubspotId: observable,
      instagramProfile: observable,
      hubspotData: observable,

      states: computed,
      hubspotId: computed,
      companyId: computed,
      companyName: computed,
      website: computed,
      instagram: computed,
      segment: computed,
      linkedin: computed,
      facebook: computed,

      mark: action,
      createContact: action,
      addCompanyContact: action,
      updateCompanyContact: action,
      deleteContact: action,
      removeContact: action,
      sendFormData: action,
    });
  }

  // computed
  get companyId() {
    return this._companyId
      ? this._companyId
      : Boolean(this?.instagramProfile?.ig_id)
      ? "ig_" + this.instagramProfile.ig_id
      : "hs_" + this?.hubspotData?.hsId;
  }

  get companyName() {
    return (
      this._companyName ||
      this.instagramProfile?.name ||
      this.hubspotData?.hsName
    );
  }

  get website() {
    return this._website || this.hubspotData?.hsDomain;
  }

  get instagram() {
    return (
      this._instagram ||
      this?.instagramProfile?.handle ||
      this.hubspotData?.hsInstagramHandle
    );
  }
  get facebook() {
    return this._facebook || this.hubspotData?.hsFacebook;
  }

  get segment() {
    return this._segment || this.hubspotData?.hsSegment;
  }
  get linkedin() {
    return this._linkedin || this.hubspotData?.hsLinkedin;
  }

  get hubspotId() {
    return this._hubspotId || this.hubspotData?.hsId;
  }

  get states() {
    // TODO think of a better way of organizing company data
    // if this._states is empty this condition returns false
    if (!Boolean(this._states === false)) {
      return this._states;
    } else {
      return this?.hubspotData?.hsZones || this.instagramProfile?.zone || [];
    }
  }

  // actions

  mark() {
    this.lastModified = new Date().getTime();
    this.marked = true;
  }

  async updateInstagramPrequalify(is_fit, stateSelection) {
    if (!this.instagramProfile || !this.instagramProfile.ig_id) return;
    const data = {
      fit: is_fit,
      ig_id: this.instagramProfile.ig_id,
      user: stores.authStore.user,
      state: stateSelection,
    };
    await createRequest("/instagram/update_prequalify", data, "POST").then(
      (data) => {
        if (data) {
          const old_id = this.companyId;
          runInAction(() => {
            this._companyId = data?.company_id;
            this._companyName = data?.company_name;
          });
          stores.companiesStore.replaceInstagramCompany(this, old_id);
        }
        // is_fit && stores.uiStore.setDataCollectCompany(this.companyId);
        is_fit && this.instagramProfile.setPrequalify("fit");
      }
    );
  }

  updateInfo() {
    stores.uiStore.setDataCollectCompany(this.companyId);
  }

  async createHubspotCompany(formData) {
    const data = {
      hsId: this.hubspotData.hsId,
      states: formData?.states,
      segment: segments[formData.segment],
      user: stores.authStore.user,
    };
    return createRequest("/hubspot/create_company", data, "POST").then(() =>
      this.mark()
    );
  }

  matchHubspotWithCompany(companyId) {
    const data = {
      hsId: this.hubspotData.hsId,
      company_id: companyId,
      user: stores.authStore.user,
    };
    createRequest("/hubspot/merge_company", data, "POST")
      .then(() => this.mark())
      .catch((e) => console.log(e));
  }

  createContact(contactData) {
    this.contacts.set(
      contactData.name + Math.random().toString(),
      new ContactModel(contactData)
    );
  }

  addCompanyContact(formData) {
    const data = {
      user: stores.authStore.user,
      company_id: this._companyId,
      hubspot_id: this.hubspotData?.hsId,
      ...formData,
    };
    createRequest("/sync_contacts", data, "POST").then((data) => {
      runInAction(() => {
        this.contacts = new observable.map();
        if (!this._companyId) {
          this._companyId = data.company_id;
          stores.companiesStore.add_company(this, true);
        }
        data.contacts.forEach((c) => {
          this.contacts.set(c.contact_id, new ContactModel(c));
        });
      });
    });
  }

  updateCompanyContact(formData) {
    const data = {
      user: stores.authStore.user,
      company_id: this.companyId,
      ...formData,
    };
    createRequest("/sync_contacts", data, "PUT").then((data) => {
      this.contacts = new observable.map();

      data.forEach((c) => {
        this.contacts.set(c.contact_id, new ContactModel(c));
      });
    });
  }

  deleteContact(contactId) {
    const data = {
      company_id: this.companyId,
      contact_id: contactId,
      user: stores.authStore.user,
    };
    createRequest("/delete_contact", data, "POST").then((data) => {
      this.contacts = new observable.map();
      data.forEach((c) => {
        this.contacts.set(c.contact_id, new ContactModel(c));
      });
    });
  }

  removeContact(contactIndex) {
    this.contacts.delete(contactIndex);
  }

  sendFormData(formData) {
    const {
      // jobId,
      phones,
      company_name,
      website,
      instagram,
      linkedin,
      facebook,
      segment,
      states,
      size,
    } = formData;

    let contactArray = [];
    this.contacts.forEach((c) => {
      contactArray.push(c.contact_id);
    });

    const data = {
      user: stores.authStore.user,
      company_id: this._companyId,
      hubspot_id: this.hubspotData?.hsId,
      company_name,
      website,
      instagram,
      linkedin,
      facebook,
      segment: segments[segment],
      states,
      size,
      contacts: contactArray,
      phones,
    };
    return createRequest("/update_company_data", data, "POST").then(() => {
      runInAction(() => {
        if (this.hubspotData?.needInfo) {
          this.hubspotData.needInfo = "";
        }
      });
      return Promise.resolve();
    });
  }
}
