import { BrowserRouter, Switch, Route } from "react-router-dom";
import SignIn from "./screens/SignIn";
import Home from "./screens/Home";
import RestrictedRoute from "RestrictedRoute";
import EnrichmentForm from "./screens/Home/Main/EnrichmentForm";

export const AppRoutes = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/companydetails" component={EnrichmentForm} />
      <Route exact path="/signin" component={SignIn} />
      <RestrictedRoute path="/" component={Home} />
    </Switch>
  </BrowserRouter>
)