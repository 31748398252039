import authStore from "./authStore";
import companiesStore from "./companiesStore";
import uiStore from "./uiStore";

class Stores {
  constructor() {
    this.companiesStore = new companiesStore();
    this.uiStore = new uiStore();
    this.authStore = new authStore();
  }
}

export const stores = new Stores();
