import { makeObservable, observable } from "mobx";

export class ContactModel {
  contact_id;
  name;
  lastname;
  title;
  phone;
  email;
  linkedin;

  constructor(contact) {
    this.contact_id = contact.contact_id;
    this.name = contact.name;
    this.lastname = contact.lastname;
    this.title = contact.title;
    this.phone = contact.phone;
    this.email = contact.email;
    this.linkedin = contact.linkedin;

    makeObservable(this, {
      // observable
      contact_id: observable,
      name: observable,
      lastname: observable,
      title: observable,
      phone: observable,
      email: observable,
      linkedin: observable,

      // computed
      // action
    });
  }
}
