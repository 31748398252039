import { Col } from "antd";
import { Label } from "./Contacts.styled";
import { ContactModal } from "./ContactModal";
import { inject, observer } from "mobx-react";
import { DeleteConfirmation } from "./DeleteConfirmation";

export const Contacts = inject("companiesStore")(
  observer((props) => {
    const company = props.companiesStore._companies.get(props.companyId);

    let contactList = [];
    company.contacts.forEach((contact, index) => {
      const { name, lastname, title, email, phone, linkedin } = contact;
      contactList.push(
      <div
        key={index}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            flex: 29,
            borderBottom: "1px solid white",
            display: "flex",
            marginBottom: 8,
            paddingBottom: 2,
          }}
        >
          <Col
            style={{ flex: 1, flexDirection: "column", display: "flex" }}
          >
            <p style={{ margin: 0 }}>
              <Label>Name:</Label> {name}
            </p>
            <p style={{ margin: 0 }}>
              <Label>Last Name:</Label> {lastname}
            </p>
            <p style={{ margin: 0 }}>
              <Label>Title:</Label> {title}
            </p>
          </Col>
          <Col
            style={{ flex: 1, flexDirection: "column", display: "flex" }}
          >
            <p style={{ margin: 0 }}>
              <Label>E-mail:</Label> {email}
            </p>
            <p style={{ margin: 0 }}>
              <Label>Phone:</Label> {phone}
            </p>
            <p style={{ margin: 0 }}>
              <Label>Linkedin:</Label> {linkedin}
            </p>
          </Col>
        </div>
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            margin: 8,
            justifyContent: "space-between",
          }}
        >
          <ContactModal edit companyId={props.companyId} contact={contact} />
          <DeleteConfirmation companyId={props.companyId} contactId={contact?.contact_id} />
        </div>
      </div>
      )
    })

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <h3 style={{ marginTop: 6 }}>Contacts</h3>
        {contactList}
        <ContactModal companyId={props.companyId} />
      </div>
    );
  })
);
