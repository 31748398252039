import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const CompanyName = styled.a`
  font-size: 20px;
`;

export const Link = styled.a`
  margin-inline: 12px;
`;
