import { useState } from "react";

import { Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";

import createRequest from "client";

export const Scraper = (props) => {
  const [processedUrls, setProcessedUrls] = useState([]);
  const [loading, setLoading] = useState(false);

  const url = props.url;

  if (!url || processedUrls.includes(url)) {
    return null;
  }

  const startSearch = () => {
    setLoading(true);
    createRequest("/crawl_url", { 
      url, 
      search_sitemap: !!props.search_sitemap, 
      "in_states": (props.in_states||[]).map(s=>s)
    }, "POST")
      .then((res) => {
        const data = res;
        if (data.emails) {
          props.setEmailsFound && props.setEmailsFound(data.emails, url);
        }
        if (data.phones) {
          props.setPhonesFound && props.setPhonesFound(data.phones, url);
        }
        if (data.face) {
          props.setFacebookFound && props.setFacebookFound(data.face, url);
        }
        if (data.insta) {
          props.setInstagramFound && props.setInstagramFound(data.insta, url);
        }
        setProcessedUrls(processedUrls.concat([url]));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div>
      <Button
        onClick={startSearch}
        loading={loading}
        type="primary"
        shape="circle"
        disabled={!props.in_states}
        icon={<SearchOutlined />}
      />
    </div>
  );
};
