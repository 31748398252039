import { makeObservable, observable, action } from "mobx";
// import { stores } from "./index.js";
import { createGetRequest } from "client";
export default class authStore {
  user = null;
  users = {
    admin: { user: "admin", pass: "J4kMEk6w", limited: false },
    serra: { user: "serra", pass: "4g7CsSFKQG", limited: false },
    turgut: {user: "turgut", pass: "SdiytTy2ErREMPe7wH2j", limited: false},
    ertugrul: { user: "ertugrul", pass: "osman", limited: true },
    sevgi: { user: "sevgi", pass: "nW7tqVqsduHPVswP", limited: false },
    yunus: { user: "yunus", pass: "nW7tqVqsduHPVswP", limited: false },
    user01: { user: "user01", pass: "J1g3e8eCXsqi0utk", limited: true },
    user02: { user: "user02", pass: "ueOtFcxHPYAVDT0E", limited: true },
    user03: { user: "user03", pass: "TpHXG9BOvtmNA80M", limited: true },
    user04: { user: "user04", pass: "gSTL9vT2HhKWT1L0", limited: true },
    user05: { user: "user05", pass: "jI0MgYM0zlQIbsiR", limited: true },
    user06: { user: "user06", pass: "NTRyJGzSvF74Njcc", limited: true },
    user07: { user: "user07", pass: "EvOUGpRZi9gZhIzf", limited: true },
    user08: { user: "user08", pass: "z3Qv0EAb5bjIJQ3C", limited: true },
    user09: { user: "user09", pass: "vAfg9zJ6Q7PscqXE", limited: true },
    user10: { user: "user10", pass: "Fe21iTOx9rBLzRGT", limited: true },
    user11: { user: "user11", pass: "dcLqV4VlYwAx6f4t", limited: true },
    user12: { user: "user12", pass: "sOny7PczqUXolm8U", limited: true },
    user13: { user: "user13", pass: "IFXLlUsnQxCo8qme", limited: true },
    user14: { user: "user14", pass: "vFs9FnRT8uCcYW9G", limited: true },
    user15: { user: "user15", pass: "m2OrNaU3grDVQVnv", limited: true },
    user16: { user: "user16", pass: "485oUfMG90wWuxRw", limited: true },
    user17: { user: "user17", pass: "lXNwCqWZVhT7Hp5O", limited: true },
    user18: { user: "user18", pass: "eoCEtxBDYrW9LabS", limited: true },
    user19: { user: "user19", pass: "wXet8mKmX23ffS3b", limited: true },
    user20: { user: "user20", pass: "5VNegI3IbXZmUrkR", limited: true },
    user21: { user: "user21", pass: "FogXpDBsk8pqCb7Q", limited: true },
    user22: { user: "user22", pass: "CVicyZiG8Dp9WQ5h", limited: true },
    user23: { user: "user23", pass: "RU6RMBw0CYA1cBwa", limited: true },
    user24: { user: "user24", pass: "36xCaPpW2eE7kD9T", limited: true },
    user25: { user: "user25", pass: "vSQ80tyxoZn7Ripy", limited: true },
    user26: { user: "user26", pass: "3xJ3x4wY0MMQ3Xzi", limited: true },
    user27: { user: "user27", pass: "4yyjZJ751DmQBaLg", limited: true },
    user28: { user: "user28", pass: "eik2J7UT5brjVp4b", limited: true },
    user29: { user: "user29", pass: "4OB0kl2KCXxqboeN", limited: true },
    user30: { user: "user30", pass: "i4D2ZSmlKOm1gvAp", limited: true },
    tester: { user: "tester", pass: "t8mKmJ1g3JQ3Ce8eC", limited: false },
  };

  authenticated = null;
  limited = false;
  number = "";

  constructor() {
    makeObservable(this, {
      user: observable,
      authenticated: observable,
      limited: observable,

      handleAuth: action,
      doLogin: action,
      fetchNumber: action,
    });
    this.handleAuth();
  }

  handleAuth = () => {
    const storedUser = window.localStorage.getItem("userData");
    let userData;

    if (storedUser) {
      userData = JSON.parse(storedUser);
    }
    if (
      userData &&
      userData.user in this.users &&
      userData.pass === this.users[userData.user].pass
    ) {
      this.user = userData.user;
      this.authenticated = true;
      this.limited = !!userData?.limited;
    } else {
      this.authenticated = false;
    }
  };

  doLogin = (username, password) => {
    if (username in this.users) {
      const { user, pass, limited } = this.users[username];
      if (user === username && pass === password) {
        window.localStorage.setItem(
          "userData",
          JSON.stringify({ user: username, pass: password, limited })
        );
        this.handleAuth();
      } else {
        alert("wrong password");
      }
    } else {
      alert("user doesn't exist");
    }
  };

  fetchNumber() {
    createGetRequest(`/user_count/${this.user}`).then((response) => {
      this.number = response;
    });
  }
}
