import { createGetRequest } from "client";
import { useState } from "react";
import { inject, observer } from "mobx-react";
import { Form, Select, Tag, Spin, Button, Modal } from "antd";
import useDebounce from "hooks/useDebounce";
import useBoolean from "hooks/useBoolean";
import FormInput from "../EnrichmentForm/FormInput";
import deliveryZones from "constants/deliveryZones";
import {
  CompanyBody,
  NeedInfoText,
  NeedInfoWrapper,
} from "./HubspotContent.styled";

const segments = [
  "Bakery",
  "Beverages",
  "Charcuterie",
  "Coffee",
  "Distillery",
  "Farm",
  "Flower",
  "General Distributor",
  "Commissary Kitchen",
  "Food",
  "Meal Delivery",
  "Laundry",
  "Other",
];

// const leadStatusEnum = {
//   ATTEMPTING_TO_CONTACT: "Attempting to Contact",
//   CONNECTED: "Connected",
//   RECYCLE_SOON: "Recycle Soon",
//   RECYCLE: "Recycle",
//   RECYCLE_LATE: "Recycle Late",
//   DISCONNECTED: "Disconnected",
//   SALES_READY: "Sales Ready",
//   NOT_CONNECTED: "Not Connected ",
// };

export const HubspotContent = inject(
  "companiesStore",
  "uiStore"
)(
  observer((props) => {
    const fetching = useBoolean(false);
    const [value, setValue] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const isVisible = useBoolean(false);
    const company = props.companiesStore.companies.get(props.compId);
    const [hubspotForm] = Form.useForm();

    // const showLeadStatus = () => {
    //   const status = company?.status;
    //   console.log(company)
    //   if (status) {
    //     return leadStatusEnum[status];
    //   } else {
    //     return "No Status";
    //   }
    // };

    const showStates = () => {
      if (company?.states.length) {
        return company.states.map((s) => <Tag>{s}</Tag>);
      }
    };

    const searchCompanies = () => {
      fetching.setTrue();
      createGetRequest(`/company_autocomplete?q=${searchTerm}`)
        .then((k) => {
          setSearchResults(k);
        })
        .catch((e) => console.log(e))
        .finally(() => fetching.setFalse());
    };

    const handleSearch = (v) => {
      if (v) setSearchTerm(v);
    };

    const handleSelectAndClear = (v) => {
      setValue(v);
    };

    const getButtonName = () => {
      return value ? "Match company" : "Create New";
    };

    const handleClick = () => {
      if (value) {
        company.matchHubspotWithCompany(value);
      } else {
        isVisible.setTrue();
      }
    };

    const getCompanyData = () => {
      return {
        companyName: company?.companyName,
        states: company.hubspotData?.hsZones,
      };
    };

    const updateInfo = () => {
      company.updateInfo();
    };

    const createCompany = (v) => {
      company.createHubspotCompany(v).then(() => isVisible.setFalse());
    };

    useDebounce(() => searchCompanies(), 1000, [searchTerm]);

    return (
      <>
        {/* <Tag style={{ marginRight: 16 }}>{showLeadStatus()}</Tag> */}
        {showStates()}
        {Boolean(company?.hubspotData?.needInfo) ? (
          <NeedInfoWrapper>
            <NeedInfoText>
              <b>Needs info:</b> {company.hubspotData.needInfo}
            </NeedInfoText>
            <Button onClick={updateInfo}>Update Info</Button>
          </NeedInfoWrapper>
        ) : (
          <CompanyBody>
            <Select
              showSearch
              allowClear
              value={value}
              style={{ flex: 1, marginRight: 16 }}
              placeholder="Search"
              defaultActiveFirstOption={false}
              showArrow={false}
              filterOption={false}
              onClear={handleSelectAndClear}
              onSearch={handleSearch}
              onSelect={handleSelectAndClear}
              notFoundContent={fetching.value ? <Spin size="small" /> : null}
            >
              {searchResults.map((c) => (
                <Select.Option key={c.company_id}>
                  {c.company_name}
                </Select.Option>
              ))}
            </Select>
            <Button onClick={handleClick}>{getButtonName()}</Button>
          </CompanyBody>
        )}

        {isVisible.value && (
          <Modal
            title="Company Data"
            visible={true}
            okText="Save"
            onOk={hubspotForm.submit}
            onCancel={isVisible.setFalse}
          >
            <Form
              onFinish={createCompany}
              form={hubspotForm}
              initialValues={getCompanyData()}
            >
              <FormInput placeholder="Company Name" name="companyName" />
              <Form.Item
                style={{ marginBottom: 8 }}
                name="segment"
                rules={[{ required: true, message: "Segment is required" }]}
              >
                <Select placeholder="Segment">
                  {segments.map((key, index) => (
                    <Select.Option key={index} value={key}>
                      {key}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item name="states">
                <Select
                  mode="multiple"
                  placeholder="States"
                  rules={[{ required: true, message: "State is required" }]}
                >
                  {Object.keys(deliveryZones).map((key, index) => (
                    <Select.Option key={index} value={key}>
                      {key}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Form>
          </Modal>
        )}
      </>
    );
  })
);
