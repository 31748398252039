import { useCallback } from "react";
import { Form, Input, Col } from "antd";
import {
  FacebookOutlined,
  GlobalOutlined,
  GoogleOutlined,
  InstagramOutlined,
  LinkedinOutlined,
} from "@ant-design/icons";

const FormInput = ({
  emailMask,
  name,
  rules,
  addonName,
  placeholder,
  inputType,
  urlArgs,
  onValueChange,
}) => {
  // e-mail mask
  const handleKeyUp = useCallback(
    (e) => {
      let value = e.currentTarget.value;
      if (emailMask) {
        value = value.trim();
        e.currentTarget.value = value;
      }
      onValueChange && onValueChange(value);
    },
    [emailMask, onValueChange]
  );

  const handleClick = () => {
    let url;
    if (addonName === "website") {
      url = urlArgs.website;
    } else if (urlArgs.instagramHandle) {
      if (urlArgs.instagramHandle.includes("instagram")) {
        url = urlArgs.instagramHandle;
      } else {
        url = "https://www.instagram.com/" + urlArgs.instagramHandle;
      }
    } else if (urlArgs.linkedinUrl) {
      url = urlArgs.linkedinUrl;
    } else if (urlArgs.facebookUrl) {
      url = urlArgs.facebookUrl;
    } else if (addonName === "linkedin" && urlArgs.contactName) {
      const contactLinkedArg =
        urlArgs.name.replace(" ", "+") +
        "+" +
        urlArgs.contactName.replace(" ", "+") +
        "+linkedin";
      url = "https://www.google.com/search?q=" + contactLinkedArg;
    } else {
      let args = urlArgs.name.replace(" ", "+");
      if (urlArgs.state) {
        args = args + "+" + urlArgs.state;
      }

      if (urlArgs.companyOwner) {
        args = args + "+Company+owner";
      }

      if (addonName !== "google") {
        args = args + "+" + addonName;
      }
      url = "https://www.google.com/search?q=" + args;
    }

    let validUrl
    try {
      validUrl = new URL(url)
    } catch (e) {
      validUrl = 'http://' + url
    }

    window.open(validUrl, "_blank");
  };

  let addon;

  switch (addonName) {
    case "google":
      addon = (
        <GoogleOutlined onClick={handleClick} style={{ cursor: "pointer" }} />
      );
      break;
    case "facebook":
      addon = (
        <FacebookOutlined onClick={handleClick} style={{ cursor: "pointer" }} />
      );
      break;
    case "linkedin":
      addon = (
        <LinkedinOutlined onClick={handleClick} style={{ cursor: "pointer" }} />
      );
      break;
    case "instagram":
      addon = (
        <InstagramOutlined
          onClick={handleClick}
          style={{ cursor: "pointer" }}
        />
      );
      break;
    case "website":
      addon = (
        <GlobalOutlined onClick={handleClick} style={{ cursor: "pointer" }} />
      );
      break;
    default:
      addon = null;
      break;
  }

  return (
    <Col style={{ flex: 1 }}>
      <Form.Item
        style={{ marginTop: 8, marginBottom: 0 }}
        name={name}
        rules={rules}
      >
        <Input
          addonAfter={addon}
          onKeyUp={handleKeyUp}
          placeholder={placeholder}
          type={inputType || "text"}
        />
      </Form.Item>
    </Col>
  );
};

export default FormInput;
