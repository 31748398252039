import { useState, useRef } from "react";

import { Button, Form, Select, Row, Col, Input } from "antd";
import { observer, inject } from "mobx-react";
import FormInput from "./FormInput";
import deliveryZones from "constants/deliveryZones";
import { Contacts } from "./Contacts";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import segments from "constants/segments";
import { Container } from "./EnrichmentForm.styled";
import {
  NeedInfoText,
  NeedInfoWrapper,
} from "../CompanyItem/HubspotContent.styled";
import { Scraper } from "components/scraper";
import { Suggestor } from "components/suggestor";
import { ContactModal } from "./ContactModal";
import { companySizes } from "constants/companySizes.constant";

export const EnrichmentForm = inject(
  "uiStore",
  "companiesStore"
)(
  observer((props) => {
    const [chosenEmail, setChosenEmail] = useState(null);
    const [emailsFound, _setEmailsFound] = useState({});
    const [phonesFound, _setPhonesFound] = useState({});
    const [instagramFound, _setInstagramFound] = useState({});
    const [facebookFound, _setFacebookFound] = useState();

    const [formData, setFormData] = useState({});

    const formRef = useRef();

    const setEmailsFound = (emails, url) => {
      let _new = { ...emailsFound };
      emails.forEach((email) => {
        if (!_new.hasOwnProperty(email)) {
          _new[email] = true;
        }
      });
      _setEmailsFound(_new);
    };

    const setPhonesFound = (phones, url) => {
      let _new = { ...phonesFound };
      phones.forEach((phone) => {
        if (!_new.hasOwnProperty(phone)) {
          _new[phone] = true;
        }
      });
      _setPhonesFound(_new);
    };

    const setFacebookFound = (facebook, url) => {
      let _new = { ...facebookFound };
      if (!_new.hasOwnProperty(facebook)) {
        _new[facebook] = true;
      }
      _setFacebookFound(_new);
    };

    const setInstagramFound = (instagram, url) => {
      let _new = { ...instagramFound };
      if (!_new.hasOwnProperty(instagram)) {
        _new[instagram] = true;
      }
      _setInstagramFound(_new);
    };

    //
    let companyId = props.dataCollectCompanyId;

    if (!companyId) {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      companyId = parseInt(urlParams.get("companyid"));
    }

    const company = props.companiesStore._companies.get(companyId);

    if (!company) {
      if (companyId) {
        props.companiesStore.getIndividualCompany(companyId);
      }
      return null;
    }

    const submit = (formFields) => {
      company.sendFormData({ jobId: props.jobId, ...formFields }).then(() => {
        props.uiStore.setDataCollectCompany(null);
      });
    };

    const updateFormData = (k, value) => {
      let _new = {};
      if (k === "phones") {
        _new["phones"] = formRef.current?.getFieldValue("phones");
      } else {
        _new[k] = value;
      }

      setFormData({ ...formData, ..._new });
      formRef.current.setFieldsValue(_new);
    };

    const getData = () => {
      const initialValues = {};
      function getKeyByValue(object, value) {
        return Object.keys(object).find((key) => {
          return object[key] === value;
        });
      }
      initialValues.company_name = company.companyName;
      initialValues.website = company._website;
      initialValues.instagram = company.instagram;
      initialValues.linkedin = company.linkedin;
      initialValues.facebook = company.facebook;
      initialValues.segment = getKeyByValue(segments, company.segment);
      initialValues.size = getKeyByValue(companySizes, company.segment);
      initialValues.states = company.states.map((s) => s);
      const parseArray = (array) => {
        const regex = /[\["\]]/g;
        console.log(array[0]);
        return array.filter((phone) => {
          if (phone) {
            return phone.replace(regex, "");
          }
        });
      };

      initialValues.phones = parseArray(company.phones);

      if (Object.keys(formData).length < 1) {
        setFormData(initialValues);
      }
      return initialValues;
    };

    const getContacts = () => {
      const contactsArray = [];
      company.contacts.forEach((c) => {
        contactsArray.push({
          contactId: c.contact_id,
          name: c.name,
          lastname: c.lastname,
          title: c.title,
          email: c.email,
          phone: c.phone,
          linkedin: c.linkedin,
        });
      });

      return contactsArray;
    };

    const formItemLayout = {
      labelCol: {
        span: 24,
      },
      wrapperCol: {
        span: 22,
      },
    };

    return (
      <Container>
        <h2>Collecting data for {company.companyName}</h2>
        {Boolean(company?.hubspotData?.needInfo) && (
          <NeedInfoWrapper>
            <NeedInfoText>
              <b>Info needed:</b> {company.hubspotData.needInfo}
            </NeedInfoText>
          </NeedInfoWrapper>
        )}

        <Form
          onFinish={submit}
          {...formItemLayout}
          initialValues={getData()}
          ref={formRef}
          onValuesChange={(changes) => {
            Object.keys(changes).map((k) => {
              updateFormData(k, changes[k]);
            });
          }}
        >
          {/* 
          Company Info
          TODO: pass values from form to formInputs instead of getting straight of company
          TODO: rerender input if its value changed
           */}
          <FormInput
            placeholder="Company Name"
            name="company_name"
            rules={[{ required: true, message: "Company name is required" }]}
            addonName="google"
            urlArgs={{
              name: formData.company_name,
              state: formData.states && formData.states[0],
            }}
          />
          <Row>
            <FormInput
              placeholder="Website"
              name="website"
              addonName={formData.website ? "website" : null}
              urlArgs={{ website: formData.website }}
            />
            <Scraper
              url={formData.website}
              in_states={formData.states}
              search_sitemap={true}
              setEmailsFound={setEmailsFound}
              setPhonesFound={setPhonesFound}
              setFacebookFound={setFacebookFound}
              setInstagramFound={setInstagramFound}
            />
          </Row>
          <Row>
            {company?.instagramProfile?.external_url &&
              company?.instagramProfile?.external_url !== formData.website && (
                <Suggestor
                  onClick={() => {
                    updateFormData(
                      "website",
                      company?.instagramProfile?.external_url
                    );
                  }}
                  text={company?.instagramProfile?.external_url}
                />
              )}
            {company?.hubspotData?.hsDomain &&
              company?.hubspotData?.hsDomain !== formData.website && (
                <Suggestor
                  onClick={() => {
                    updateFormData("website", company?.hubspotData?.hsDomain);
                  }}
                  text={company?.hubspotData?.hsDomain}
                />
              )}
          </Row>
          <FormInput
            placeholder="Instagram"
            name="instagram"
            addonName="instagram"
            urlArgs={{
              name: formData.company_name,
              instagramHandle: formData.instagram,
            }}
          />
          {instagramFound && (
            <div style={{ display: "flex", flexDirection: "row" }}>
              {Object.keys(instagramFound).map((i) => (
                <Suggestor
                  onClick={() => {
                    updateFormData(
                      "instagram",
                      "https://www.instagram.com/" + i
                    );
                  }}
                  text={i}
                />
              ))}
            </div>
          )}
          <FormInput
            placeholder="LinkedIn"
            name="linkedin"
            addonName="linkedin"
            urlArgs={{
              name: formData.company_name,
              linkedinUrl: formData.linkedin,
            }}
          />
          <Col>
            <FormInput
              style={{ marginBottom: 8 }}
              placeholder="Facebasdfasdook"
              name="facebook"
              addonName="facebook"
              urlArgs={{
                name: formData.company_name,
                facebookUrl: formData.facebook,
              }}
            />
            {/* <Scraper
              url={formData.facebook ? (formData.facebook.includes("/about")? formData.facebook: formData.facebook+ "/about"):null }
              in_states={[]}
              search_sitemap={false}
              setEmailsFound={setEmailsFound}
              setPhonesFound={setPhonesFound}
              setFacebookFound={setFacebookFound}
              setInstagramFound={setInstagramFound}
            /> */}
          </Col>

          {facebookFound && (
            <div style={{ display: "flex", flexDirection: "row" }}>
              {Object.keys(facebookFound).map((i) => (
                <Suggestor
                  onClick={() => {
                    updateFormData("facebook", "https://www.facebook.com/" + i);
                  }}
                  text={i}
                />
              ))}
            </div>
          )}

          <Col>
            <Form.Item
              style={{ marginTop: 8, marginBottom: 8 }}
              name="segment"
              rules={[{ required: true, message: "Segment is required" }]}
            >
              <Select
                placeholder="Segment"
                // value={availableStates}
                // onChange={setAvailableStates}
              >
                {Object.keys(segments).map((key, index) => (
                  <Select.Option key={index} value={key}>
                    {key}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              style={{ marginBottom: 8 }}
              name="size"
              rules={[{ required: true, message: "Company size is required" }]}
            >
              <Select
                placeholder="Company size"
                // value={availableStates}
                // onChange={setAvailableStates}
              >
                {companySizes.map((companySize, index) => (
                  <Select.Option key={index} value={companySize}>
                    {companySize}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Row>
            <Form.Item
              name="states"
              rules={[{ required: true, message: "State is required" }]}
            >
              <Select
                placeholder="States"
                mode="multiple"
                showSearch
                filterOption={(input, option) => {
                  if (
                    option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  ) {
                    return true;
                  }

                  const indexes = [];
                  Object.values(deliveryZones).forEach((dz, index) => {
                    if (dz.name.toLowerCase().includes(input.toLowerCase())) {
                      indexes.push(index);
                    }
                  });
                  return indexes.includes(parseInt(option.key, 10));
                }}
                onSearch={() => {}}
                rules={[{ required: true, message: "State is required" }]}
              >
                {Object.keys(deliveryZones).map((key, index) => (
                  <Select.Option key={index} value={key}>
                    {key}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Row>

          <Form.List name="phones">
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map((field, index) => (
                  <Form.Item
                    {...formItemLayout}
                    label={index === 0 ? "Phones" : ""}
                    required={false}
                    key={field.key}
                  >
                    <Form.Item
                      {...field}
                      validateTrigger={["onChange", "onBlur"]}
                      noStyle
                    >
                      <Input placeholder="phone" style={{ width: "100%" }} />
                    </Form.Item>
                    <MinusCircleOutlined
                      style={{ marginLeft: 8 }}
                      className="dynamic-delete-button"
                      onClick={() => remove(field.name)}
                    />
                  </Form.Item>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    style={{ width: "60%" }}
                    icon={<PlusOutlined />}
                  >
                    Add phone
                  </Button>
                  <Form.ErrorList errors={errors} />
                </Form.Item>
                {phonesFound && (
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    {Object.keys(phonesFound).map((i) => {
                      if (formRef.current.getFieldValue("phones").includes(i)) {
                        return null;
                      }
                      return (
                        <Suggestor
                          onClick={() => {
                            add(i);
                          }}
                          text={i}
                        />
                      );
                    })}
                  </div>
                )}
              </>
            )}
          </Form.List>

          {/* Contacts */}

          <Contacts companyId={company.companyId} contacts={getContacts()} />
          {chosenEmail && (
            <ContactModal
              defaultVisible={true}
              defaultEmail={chosenEmail}
              companyId={company.companyId}
              onClose={() => {
                setChosenEmail(null);
              }}
            />
          )}

          {emailsFound && (
            <div style={{ display: "flex", flexDirection: "row" }}>
              {Object.keys(emailsFound).map((i) => {
                if (
                  getContacts()
                    .map((c) => c.email)
                    .includes(i)
                ) {
                  return null;
                }
                return (
                  <Suggestor
                    onClick={() => {
                      setChosenEmail(i);
                    }}
                    text={i}
                  />
                );
              })}
            </div>
          )}

          <Form.Item>
            <Row>
              <Button
                type="primary"
                style={{ marginTop: 8, marginRight: 8 }}
                htmlType="submit"
              >
                Submit
              </Button>
              <Button
                style={{ margin: 8 }}
                onClick={() => {
                  props.uiStore.setDataCollectCompany(null);
                }}
              >
                Close
              </Button>
            </Row>
          </Form.Item>
        </Form>
      </Container>
    );
  })
);
