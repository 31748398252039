import { DeleteOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import { inject, observer } from "mobx-react";
import useBoolean from "hooks/useBoolean";

export const DeleteConfirmation = inject("companiesStore")(
  observer((props) => {
    const company = props.companiesStore._companies.get(props?.companyId);
    const {contactId} = props
    const isVisible = useBoolean(false);

    const deleteContact = () => {
      // request api for deletion if the current contact has a id
      if (contactId) {
        company.deleteContact(contactId)
        isVisible.setFalse();
      } 
      isVisible.setFalse();
    };

    const closeModal = () => {
      isVisible.setFalse();
    };

    return (
      <>
        <DeleteOutlined
          onClick={isVisible.setTrue}
          style={{ cursor: "pointer", fontSize: 20 }}
        />
        <Modal
          visible={isVisible.value}
          onOk={deleteContact}
          okText="Delete"
          onCancel={closeModal}
          okButtonProps={{ danger: true }}
        >
          <p>Are you sure do you want to delete this contact?</p>
        </Modal>
      </>
    );
  })
);
