import { makeObservable, observable, action } from "mobx";

export class InstagramModel {
  company;
  company_id;
  ig_id;
  handle;
  name;
  ig_category;
  bio;
  follows_count;
  followers_count;
  prequalify = null;
  external_url;
  zone=[]

  constructor(company, profile) {
    this.company = company

    this.ig_id = profile.ig_id;
    this.handle = profile.handle;
    this.name = profile.name || this.handle
    this.ig_category = profile.ig_category;
    this.bio = profile.bio;
    this.follows_count = profile.follows_count;
    this.followers_count = profile.followers_count;
    this.external_url = profile.external_url;
    this.zone = profile.zone;
    makeObservable(this, {
      // observable
      ig_id: observable,

      handle: observable,
      name: observable,
      ig_category: observable,
      bio: observable,
      follows_count: observable,
      followers_count: observable,
      external_url: observable,
      prequalify: observable,
      zone:observable,

      setPrequalify: action,
    });
  }

  setPrequalify(condition) {
    this.prequalify = condition;
    this.company.mark()
  }
}
