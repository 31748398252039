import React from "react";
import { useEffect } from "react";
import { parse, format } from "date-fns";

import { inject, observer } from "mobx-react";

export const DriverJobDescription = inject(
  "companiesStore",
  "uiStore"
)(
  observer((props) => {
    let _scrollingDiv;

    const companiesStore = props.companiesStore;
    const hovered = props.hovered;
    const compId =
      props.uiStore.dataCollectCompany || (hovered && hovered.compId);
    let comp = null;
    let instagramProfile = null;
    if (compId) {
      comp = companiesStore.companies.get(compId);
    }
    if (comp) {
      instagramProfile = comp.instagramProfile;
    }
    let job = null;
    if (comp && hovered.jobId) {
      job = comp.jobs.get(hovered.jobId);
    }

    useEffect(() => {
      _scrollingDiv.scrollTop = 0;
    }, [job, instagramProfile, _scrollingDiv]);

    return (
      <div
        ref={(c) => (_scrollingDiv = c)}
        style={{ width: 400, height: "100%", overflowY: "auto", padding: 12 }}
      >
        {instagramProfile && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              borderBottom: "2px solid white",
              paddingBottom: 16,
            }}
          >
            <div style={{ paddingTop: 16, fontSize: 32, fontWeight: "bold" }}>
              Instagram
            </div>
            <div
              style={{
                marginTop: 0,
                paddingBottom: 10,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <a
                className="ant-page-header-heading-sub-title"
                href={"https://www.instagram.com/" + instagramProfile.handle}
                rel="noreferrer"
                target="_blank"
              >
                {instagramProfile.handle}
              </a>
              <a
                className="ant-page-header-heading-sub-title"
                href={
                  "https://www.google.com/search?q=" + instagramProfile.handle
                }
                rel="noreferrer"
                target="_blank"
              >
                google
              </a>
            </div>
            <div>{instagramProfile.bio}</div>
            <a
              href={instagramProfile.external_url}
              rel="noreferrer"
              target="_blank"
            >
              {instagramProfile.external_url}
            </a>
          </div>
        )}
        {job && (
          <div style={{ fontWeight: "bold", paddingTop: 16 }}>
            {format(parse(job.jobDate, "yyyy-MM-dd", new Date()), "MM/dd/yyyy")}
          </div>
        )}
        {job && (
          <div
            style={{
              fontSize: 32,
              fontWeight: "bold",
            }}
          >
            {" "}
            {job.jobTitle}
          </div>
        )}
        {job && <div style={{ marginBottom: 8 }}>{job.jobLocation}</div>}

        {job && (
          <div dangerouslySetInnerHTML={{ __html: job.descriptionHtml }} />
        )}
      </div>
    );
  })
);
