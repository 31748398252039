import { Button, Input } from "antd";
import { inject, observer } from "mobx-react";
import { useState } from "react";
import { Redirect } from "react-router-dom";

export const SignIn = inject("authStore")(
  observer((props) => {
    const authenticated = props.authStore.authenticated;

    const [loginData, setLoginData] = useState({ user: null, password: null });

    const handleLogIn = () => {
      props.authStore.doLogin(loginData.user, loginData.password);
    };

    const handleOnChange = (e, type) => {
      setLoginData(oldState => ({...oldState, [type]: e.target.value}))
    };

    const handleEnter = () => {
      if (isButtonDisabled()) {
        return null;
      } else {
        handleLogIn();
      }
    };

    const isButtonDisabled = () => {
      return !loginData.user || !loginData.password;
    };

    if (authenticated) {
      return <Redirect to={"/"} />;
    }

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          margin: "auto",
          width: "50%",
          marginTop: 64,
        }}
      >
        <Input
          onPressEnter={handleEnter}
          onChange={(e) => handleOnChange(e, "user")}
          placeholder="User"
        />
        <Input.Password
          onPressEnter={handleEnter}
          onChange={(e) => handleOnChange(e, "password")}
          placeholder="Password"
        />
        <Button onClick={handleLogIn} style={{ marginTop: 16 }} disabled={isButtonDisabled()}>
          Log In
        </Button>
      </div>
    );
  })
);
