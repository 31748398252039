const Colors = {
	primary: '#642AB5', // Light violet
	heading: 'rgba(255, 255, 255, 0.85)', // Bright white
	success: '#52C41A', // Light green
	success2: '#274916', // Dark green
	success3: '#162312', // Very Dark green
	error: '#FF4D4F', // Red
	warning: '#FAAD14', // Dark yellow
	disabled: 'rgba(255, 255, 255, 0.3)', // Weak grey

	primary1: '#1A1325', // Dark violet
	primary2: '#24163A', // Dark violet
	primary3: '#301C4D', // Dark violet
	primary4: '#301C4D', // Dark violet
	primary5: '#51258F', // Light violet
	primary7: '#854ECA', // Lighter violet
	primary8: '#AB7AE0', // Even lighter violet
	primary9: '#CDA8F0', // Much lighter violet
	primary10: '#EBD7FA', // Mega light violet

	text: 'rgba(255, 255, 255, 0.65)', // Clear grey
	textSecondary: 'rgba(255, 255, 255, 0.45)', // Less clear grey
	textInverse: '#000000', // Totally Black

	neutral1: '#141414', // Different shade of black
	neutral2: '#141414', // Different shade of black
	neutral3: '#1F1F1F', // Different shade of black
	neutral5: '#434343', // Different shade of black
	neutral6: '#595959', // Different shade of black
	neutral8: '#BFBFBF', // Clear grey

	request: '#A61D24', // Dark Red

	polarGreen3: '#B7EB8F', // Light green

	volcano2: '#FFD8BF',
	volcano6: '#FA541C', // Red

	dustRed5: '#A61D24',

	orange5: '#AA6215',

	borderColor: '#303030',

	background: '#141414',

	dropShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',

	white: '#fff',
};

export default Colors