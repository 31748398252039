import { useEffect, useCallback, useState } from "react";
import { Tabs, Dropdown, Button, Menu, List, Spin, Switch } from "antd";
import { DownOutlined, UserOutlined } from "@ant-design/icons";
import { inject, observer } from "mobx-react";
import CompanyItem from "./CompanyItem";
import deliveryZones from "constants/deliveryZones";
import { format } from "date-fns";
import { Number } from "components/NumberOfLeads";

import InfiniteScroll from "react-infinite-scroller";
import EnrichmentForm from "./EnrichmentForm";

const { TabPane } = Tabs;

export const Main = inject(
  "companiesStore",
  "uiStore",
  "authStore"
)(
  observer((props) => {
    const [showHistoric, setShowHistoric] = useState(false);
    const [blacklist, setBlacklist] = useState([]);
    const isLimited = props.authStore.limited;

    const getData = useCallback(
      (tab) => {
        if (tab === "driverjobs") props.companiesStore.getDriverJobs();
        if (tab === "instagram") props.companiesStore.getInstagramLeads();
        if (tab === "hubspot") props.companiesStore.getHubspotCompanies();
      },
      [props.companiesStore]
    );

    useEffect(() => {
      if (isLimited && props.uiStore.show !== "hubspot") {
        props.uiStore.setHubspotAsDefault();
      }
    }, [isLimited]);

    useEffect(() => {
      const activeTab = props.uiStore.show;
      getData(activeTab);
    }, [props.uiStore.show]);

    const lastScraped = props.companiesStore.lastScrapedDate;
    const menu = (
      <Menu
        onClick={(e) => {
          props.uiStore.setZone(e.key);
          props.companiesStore.getDriverJobs();
        }}
      >
        {Object.entries(deliveryZones).map((z) => {
          if (Boolean(z[1]?.activeState)) {
            return <Menu.Item key={z[0]}>{z[1].name}</Menu.Item>;
          } else {
            return null;
          }
        })}
      </Menu>
    );

    // TODO useArray here could be nice
    const getCompanies = () => {
      const companiesWhitelist = [
        ...[...props.companiesStore.companies.values()].filter(
          (c) => !blacklist.includes(c.companyId)
        ),
      ];
      const markedCompaniesArray = [...props.companiesStore.marked.values()];

      if (props.uiStore.show === "driverjobs" && showHistoric) {
        return markedCompaniesArray.sort(
          (a, b) => b.lastModified - a.lastModified
        );
      } else if (props.uiStore.show === "driverjobs") {
        // :)
        const getJobs = (company) => [...company.jobs.values()];
        return companiesWhitelist.sort((a, b) => {
          const [aDate, bDate] = [a, b].map(
            (company) =>
              getJobs(company).reduce((a, b) => (a.jobDate > b.jobDate ? a : b))
                .jobDate
          );
          return aDate > bDate ? -1 : 1;
        });
      }
      if (props.uiStore.show === "instagram" && showHistoric) {
        return markedCompaniesArray.sort(
          (a, b) => b.lastModified - a.lastModified
        );
      } else if (props.uiStore.show === "instagram")
        return companiesWhitelist.filter(
          (c) => c.instagramProfile.prequalify === null
        );
      if (props.uiStore.show === "hubspot")
        return companiesWhitelist.filter((c) => {
          return c.hubspotData?.needInfo; //OU:Not needed for now || (c.hubspotData?.hsId && !c._companyId)
        });
    };

    const handleLoadMore = () => {
      if (props.uiStore.show === "instagram") {
        props.companiesStore.getInstagramLeads();
      } else if (props.uiStore.show === "driverjobs") {
        props.companiesStore.getDriverJobs();
      } else if (props.authStore.limited) {
        props.companiesStore.selfAssign();
      } else {
        props.companiesStore.getHubspotCompanies();
      }
    };

    const onJobHover = (a) => {
      props.onJobHover && props.onJobHover(a);
    };

    let dataCollectCompany = props.companiesStore.companies.get(
      props.uiStore.dataCollectCompany
    );

    // TODO Passing jobId like this is not good. Find a better way
    if (dataCollectCompany) {
      let jobId = props.hovered.jobId;
      if (props.uiStore.show === "instagram") jobId = null;
      return (
        <EnrichmentForm
          jobId={jobId}
          dataCollectCompanyId={props.uiStore.dataCollectCompany}
        />
      );
    }
    return (
      <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
        <Tabs
          activeKey={props.uiStore.show}
          onChange={(k) => {
            props.uiStore.setShow(k);
          }}
        >
          {!isLimited && (
            <>
              <TabPane tab="Driver Jobs" key="driverjobs"></TabPane>
              <TabPane tab="Instagram" key="instagram"></TabPane>
            </>
          )}
          <TabPane tab="Enrich" key="hubspot"></TabPane>
        </Tabs>

        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            {props.authStore.user && (
              <div>
                <UserOutlined style={{ marginRight: 8 }} />
                <span>{props.authStore.user}</span>
              </div>
            )}
            {props.uiStore.show === "driverjobs" && (
              <>
                <Dropdown overlay={menu}>
                  <Button>
                    {props.uiStore.zone} <DownOutlined />
                  </Button>
                </Dropdown>
                {lastScraped && (
                  <div style={{ fontWeight: "bold" }}>
                    Last Scrape:{" "}
                    {format(new Date(lastScraped * 1000), "MM/dd/yyyy")}
                  </div>
                )}
                <p style={{ marginTop: 12 }}>
                  Nº of jobs:{" "}
                  {props.companiesStore?.jobsPerState?.[props.uiStore.zone]}
                </p>
              </>
            )}
            {props.uiStore.show !== "hubspot" ? (
              <div style={{ display: "flex", alignItems: "center" }}>
                <h3 style={{ marginBottom: 0, marginRight: 8 }}>Historic</h3>
                <Switch onClick={() => setShowHistoric(!showHistoric)} />
              </div>
            ) : (
              <Number />
            )}
          </div>

          <InfiniteScroll
            initialLoad={false}
            pageStart={0}
            threshold={3000}
            loadMore={() => null}
            //hasMore={!this.state.loading && driverJobsStore.backendHasMore}
            useWindow={false}
            style={{
              flex: 1,
              overflowY: "auto",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <List
              style={{ paddingLeft: 16 }}
              dataSource={getCompanies()}
              renderItem={(item) => (
                <CompanyItem
                  // showJobTypes={this.state.showJobTypes}
                  // showLabel={this.state.showLabel}
                  showHistoric={showHistoric}
                  hovered={props.hovered}
                  onJobHover={onJobHover}
                  key={item.companyId}
                  compId={item.companyId}
                  addToBlacklist={() => {
                    const filteredCompanies = [
                      ...props.companiesStore.companies.values(),
                    ]
                      .filter((company) => company.companyId === item.companyId)
                      .map((company) => company.companyId);
                    setBlacklist([...blacklist, ...filteredCompanies]);
                  }}
                />
              )}
            ></List>
            {props.companiesStore.loadingData ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 10,
                }}
              >
                <Spin />
              </div>
            ) : (
              <Button
                style={{ width: "8em", alignSelf: "center" }}
                onClick={handleLoadMore}
              >
                Load More
              </Button>
            )}
          </InfiniteScroll>
        </>
        <div>v1.1</div>
      </div>
    );
  })
);
